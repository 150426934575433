/* External dependencies */
import React from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';

/* Internal dependencies */
import './PostPreview.scss';

type Props = {
  post: any;
};

class PostPreview extends React.Component<Props> {
  render() {
    const { post } = this.props;
    const { title, description = '', date, images, slug, providerId } = post;
    const momentDate = moment(date);

    return (
      <div className="bm-PostPreview d-flex flex-wrap align-items-start mb-5">
        {images && images[0] && (
          <div className="bm-PostPreview_image rounded">
            <Link to={`/the-beat/${providerId}/${slug}`} className="rounded" style={{ width: '100%' }}>
              <img
                className="rounded"
                src={images[0].url}
                width="100%"
                alt={slug}
                style={{
                  flexShrink: 0,
                  objectFit: 'cover',
                }}
              />
            </Link>
          </div>
        )}
        <div className="bm-PostPreview_text">
          <Link className="text-dark" to={`/the-beat/${providerId}/${slug}`} style={{ textDecoration: 'none' }}>
            {title ? <h2 className="text-bold mb-2">{title}</h2> : <h2 className="text-bold mb-2">{(description as string).split(' ').slice(0,5).filter(str => !str.startsWith('#') && !str.startsWith('http')).join(' ')}...</h2>}
          </Link>
          {description && <p className="mb-2">{description.replace(/(https?:\/\/\S+)/, '')}</p>}
          <Link className="pb-4" to={`/the-beat/${providerId}/${slug}`}>Read more</Link>
          <p className="mt-4">Posted {momentDate.fromNow()}</p>
        </div>
      </div>
    );
  }
}

export default PostPreview;