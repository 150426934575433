/* External dependencies */
import React from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

/* Internal dependencies */
import MediaObject, { DEFAULT_IMAGE_SIZE } from '../mediaObject/MediaObject';
import Player from '../player/Player';
import Spinner from '../spinner/Spinner';

type OwnProps = { 
  track: any;
  right?: React.ReactNode;
};

type Props = OwnProps;

const Track: React.SFC<Props> = ({ track, right }) => {
  return (
    <MediaObject
      text={track.name}
      subtext={track.artists[0].name}
      imageType="rounded"
      image={(
        <div style={{ position: 'relative' }}>
          <img
            alt={track.name}
            src={track.images[0].url}
            style={{
              height: DEFAULT_IMAGE_SIZE,
              width: DEFAULT_IMAGE_SIZE,
              overflow: 'hidden',
              objectFit: 'cover',
              borderRadius: 10,
            }}
          />
          {track.previewUrl && (
            <Player
              track={track}
              render={({ play, pause, loading, playing, progress }) => (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgressbarWithChildren
                    value={Math.floor(progress * 100)}
                    maxValue={100}
                    strokeWidth={12}
                    styles={{
                      root: { width: 58, height: 58, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, margin: 0 },
                      path: {
                        stroke: '#9966ff',
                      },
                    }}
                  >
                    <button
                      className="btn"
                      disabled={loading}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        playing && pause();
                        !playing && play();
                      }}
                      style={{ display: 'flex', flexDirection: 'column', height: 50, width: 50, borderRadius: 25, justifyContent: 'center', alignItems: 'center', overflow: 'hidden', padding: 0, margin: 0, maxHeight: 50 }}
                    >
                      {!loading && playing && <i className="material-icons" style={{ backgroundColor: '#96f', fontSize: 60, color: '#fff', opacity: 0.95 }}>pause_circle_filled</i>}
                      {!loading && !playing && <i className="material-icons" style={{ backgroundColor: '#96f', fontSize: 60, color: '#fff', opacity: 0.95 }}>play_circle_filled</i>}
                      {loading && <Spinner />}
                    </button>
                  </CircularProgressbarWithChildren>
                </div>
              )}
            />
          )}
        </div>
      )}
      right={right}
    />
  );
};

export default Track;
