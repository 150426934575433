/* External dependencies */
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Helmet } from 'react-helmet';

/* Internal dependencies */
import amplifyInit from './aws-exports';
import './App.scss';
import Home from './home/Home';
import Music from './music/Music';
import EditTasteTest from './tasteTest/EditTasteTest';
import store from './store';
import AuthRoutes from './AuthRoutes';
import ViewTaste, { ViewTasteRedirect } from './tasteTest/ViewTaste';
import ViewResult from './tasteTest/ViewResult';
import About from './about/About';
import { updateCurrentUser, CurrentUserState, updateCurrentUserLoading } from './store/ducks/currentUser';
import { getUser } from './api/users';
import { modelUser } from './store/helpers/users';
import ScrollToTop from './scrollToTop/ScrollToTop';
import Navbar from './navbar/Navbar';
import NotificationsContainer from './notifications/NotificationsContainer';
import Blog from './blog/Blog';
import Post from './blog/Post';

// Initialize AWS Amplify client
amplifyInit();

export const NotFound = () => (
  <>
    <Helmet>
      <title>Beamatch | Not Found - 404</title>
    </Helmet>
    <Navbar />
    <div className="container d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
      <h2>Uh oh. You're way off beat!</h2>
      <div>
        <p className="lead">The page you requested cannot be found. Try another page.</p>
      </div>
    </div>
  </>
);

class App extends React.Component {
  async componentDidMount() {
    await this.getSignedInStatus();
  }

  getSignedInStatus = async () => {
    try {
      store.dispatch(updateCurrentUserLoading(true));
      const { identityId, authenticated } = await Auth.currentUserCredentials();
      if (identityId && authenticated) {
        const user = await getUser(`user:${identityId}`);
        this.updateCurrentUser(modelUser(user));

        return true;
      }
    } catch (e) {
      console.error('auth error', e);
    } finally {
      this.setState({ loading: false });
      store.dispatch(updateCurrentUserLoading(false));
    }
    return false;
  };

  updateCurrentUser = (user: CurrentUserState['user']) => {
    store.dispatch(updateCurrentUser(user))
  };

  render() {
    return (
      <Provider store={store}>
        <Router>
          <ScrollToTop />
          <div id="bm" style={{ minHeight: '100vh', height: '100%' }}>
            <Switch>
              <Route
                exact
                key="home"
                path="/"
                component={Music}
                // component={Home}
                // component={() => <Redirect to="/music" />}
              />
              <Route
                exact
                key="home"
                path="/music"
                component={Music}
              />
              <Route
                path="/taste"
                key="viewTasteRedirect"
                exact={true}
                component={ViewTasteRedirect}
              />
              <Route
                key="viewTaste"
                path="/taste/user:us-west-2::userId/preview"
                exact={true}
                component={({ match: { params: { userId }}}: any) => <Redirect to={`/taste/${userId}/preview`} />}
              />
              <Route
                key="viewTaste"
                path="/taste/:userId/preview"
                exact={true}
                component={ViewTaste}
              />
              <Route
                key="viewTaste"
                path="/taste/user:us-west-2::userId"
                exact={true}
                component={({ match: { params: { userId }}}: any) => <Redirect to={`/taste/${userId}`} />}
              />
              <Route
                key="viewTaste"
                path="/taste/:userId"
                exact={true}
                component={ViewTaste}
              />
              <Route
                key="viewResult1"
                path="/taste/user:us-west-2::userId/results/result::resultId"
                exact={true}
                component={({ match: { params: { userId, resultId }}}: any) => <Redirect to={`/taste/${userId}/results/${resultId}`} />}
              />
              <Route
                key="viewResult2"
                path="/taste/:userId/results/result::resultId"
                exact={true}
                component={({ match: { params: { userId, resultId }}}: any) => <Redirect to={`/taste/${userId}/results/${resultId}`} />}
              />
              <Route
                key="viewResultTab"
                path="/taste/:userId/results/:resultId/:tab"
                exact={true}
                component={ViewResult}
              />
              <Route
                key="viewResult3"
                path="/taste/:userId/results/:resultId"
                exact={true}
                component={(props: any) => <ViewResult {...props} />}
              />
              <Route
                key="about"
                path="/about"
                exact={true}
                component={About}
              />
              <Route
                key="blogPost"
                path="/the-beat/:postId/:slug"
                exact={true}
                component={Post}
              />
              <Route
                key="blogPost"
                path="/the-beat/:postId"
                exact={true}
                component={Post}
              />
              <Route
                key="blog"
                path="/the-beat"
                exact={true}
                component={Blog}
              />
              <Route
                key="editTaste1"
                path="/taste/user:us-west-2::userId/edit"
                exact={true}
                component={({ match: { params: { userId }}}: any) => <Redirect to={`/taste/${userId}/edit`} />}
              />
              <Route
                key="editTaste2"
                path="/taste/:userId/edit"
                exact={true}
                component={EditTasteTest}
              />
              <Route
                key="sync"
                path="/sync"
                component={() => null}
              />
              <Route
                key="notFound"
                component={NotFound}
                path="/not-found"
              />
              <Route
                key="404"
                component={NotFound}
              />
            </Switch>
          </div>
        </Router>
        <NotificationsContainer />
      </Provider>
    );
  }
}

export default App;
