/* External dependencies */
import React from 'react';
import { connect } from 'react-redux';

/* Internal dependencies */
import { ApplicationState } from 'src/store';
import { getNotifications } from 'src/store/ducks/notifications';
import Notification from './Notification';
import { Notification as NotificationType } from 'src/types/Notification';

type StateProps = {
  notifications: NotificationType[];
}

type Props = StateProps;

class NotificationsContainer extends React.Component<Props> {
  render() {
    const { notifications = [] } = this.props;
    return (
      <div style={{ position: 'fixed', bottom: 16, right: 16 }}>
        {notifications.map(notification => (
          <div className="mb-2">
            <Notification notification={notification} />
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  notifications: getNotifications(state),
});

export default connect(mapStateToProps)(NotificationsContainer);