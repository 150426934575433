/* External dependencies */
import React from 'react';
import Helmet from 'react-helmet';

/* Internal dependencies */
import { listPosts } from 'src/api/posts';
import PostPreview from './PostPreview';
import './Blog.scss';
import Footer from '../footer/Footer';
import LogoAsURL from '../assets/images/beatmatch-horizontal.svg';
import Navbar from 'src/navbar/Navbar';
import Spinner from 'src/spinner/Spinner';

type Props = {};

type State = {
  posts: any[];
  loading: boolean;
};

class Blog extends React.Component<Props, State> {
  state = { posts: [], loading: true };

  async componentDidMount() {
    await this.loadPosts();
  }

  loadPosts = async () => {
    try {
      this.setState({ loading: true });
      const { items: posts } = await listPosts();
      this.setState({ posts });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { posts, loading } = this.state;

    return (
      <div className="bg-white" style={{ minHeight: '100vh' }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Beatmatch | The Beat</title>
          <meta property="og:description" content="Beatmatch is a social platform for connecting people through music and technology." />
          <meta property="og:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}/the-beat`} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Beatmatch | The Beat" />
          <meta property="og:image" content={`https://${process.env.REACT_APP_DOMAIN_NAME}${LogoAsURL}`} />
          <meta property="og:site_name" content="Beatmatch" />
          <link rel="canonical" href={`https://${process.env.REACT_APP_DOMAIN_NAME}/the-beat`} />
        </Helmet>
        <Navbar showLogo={true} alwaysFillNavbar={false} />
        <section  style={{ minHeight: '100vh' }}>
          <section className="bm-Blog_header bg-primary d-flex justify-content-center align-items-center p-5" style={{ marginTop: -75, minHeight: 475 }}>
            <div className="container d-flex justify-content-center">
              <div className="rounded d-flex justify-content-center p-5" style={{ overflow: 'hidden' }}>
                <h1 className="text-white text-center p-0 m-0">The Beat</h1>
              </div>
            </div>
          </section>
          <section className="container pt-5 pb-5 d-flex justify-content-between flex-wrap">
            {posts.map(post => (
              <div className="bm-Blog_postContainer mb-3">
                <PostPreview post={post} />
              </div>
            ))}
            {loading && <Spinner />}
          </section>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Blog;