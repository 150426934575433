/* External dependencies */
import { API } from 'aws-amplify';

/* Internal dependencies */
import { ApiName } from '../aws-exports';
import { User, Image } from '../types/User';


export const getUser = async (userId: string): Promise<User> => await API.get(ApiName.bmApp, `/users/${userId}`, null);

export const addImageForUser = async (userId: string, image: Image) =>
  await API.post(ApiName.bmApp, `/users/${userId}/images`, {
    body: image,
  });