/* External dependencies */
import React from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import Cognito from 'aws-sdk/clients/cognitoidentity';

/* Internal dependencies */
import './App.scss';
import { ApplicationState } from './store';
import { getUser } from './api/users';
import { modelUser } from './store/helpers/users';
import { User } from './types/User';
import { Dispatch } from 'redux';
import { CurrentUserState, updateCurrentUser, getCurrentUser, updateCurrentUserLoading } from './store/ducks/currentUser';
import Navbar from './navbar/Navbar';
import Spinner from './spinner/Spinner';
import AuthModal from './authModal/AuthModal';

type OwnProps = {
  showNavbar?: boolean;
  children: React.ReactNode;
  alwaysFillNavbar?: boolean;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
  updateCurrentUser(user: User): void;
  updateCurrentUserLoading(loading: CurrentUserState['loading']): void;
};

type Props = OwnProps & StateProps;

type State = {
  isSignedIn: boolean | undefined;
  loading: boolean;
  showAuthModal?: boolean;
};

const cognitoIdentity = new Cognito({ region: 'us-west-2' });

class AuthRoutes extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isSignedIn: undefined, loading: true, showAuthModal: undefined };
  }

  async componentDidMount() {
     await this.getSignedInStatus(); 
  }

  getSignedInStatus = async () => {
    const { updateCurrentUser, updateCurrentUserLoading } = this.props;

    try {
      updateCurrentUserLoading(true);
      // TODO: fix issue with login state not persisting on page reload
      const [{ IdentityId: identityId }, { authenticated }] = await Promise.all([
        cognitoIdentity.getId({
          AccountId: process.env.REACT_APP_ACCOUNT_ID || '',
          IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID || '',
        }).promise(),
        Auth.currentUserCredentials(),
      ]);
      console.log('identityId', identityId);
      console.log('authenticated', authenticated);
      if (identityId) {
        const user = await getUser(`user:${identityId}`);
        updateCurrentUser(modelUser(user));

        return true;
      } else {
        // updateCurrentUser(null);
      }
    } catch (e) {
      console.error('auth error', e);
    } finally {
      this.setState({ loading: false });
      updateCurrentUserLoading(false);
    }
    return false;
  };

  handleAuthModalOpen = () => {
    this.setState({ showAuthModal: true });
  };

  handleAuthModalClose = () => {
    this.setState({ showAuthModal: false });
  };

  handleSuccess = () => {
    const { showAuthModal } = this.state;
    this.setState(({ showAuthModal }) => ({ showAuthModal: showAuthModal === true && false }));
  };

  render() {
    const { children, currentUser, showNavbar = true, alwaysFillNavbar = false } = this.props;
    const { loading, showAuthModal } = this.state;

    return (
      <React.Fragment>
        {showNavbar && <Navbar alwaysFillNavbar={alwaysFillNavbar} />}
        {/* {loading && (<Spinner />)} */}
        {currentUser && children}
        <AuthModal show={showAuthModal !== undefined ? showAuthModal : !currentUser && !loading} onShow={this.handleAuthModalOpen} onClose={this.handleAuthModalClose} onSuccess={this.handleSuccess} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCurrentUser: (user: CurrentUserState['user']) => { dispatch(updateCurrentUser(user)); },
  updateCurrentUserLoading: (loading: CurrentUserState['loading']) => { dispatch(updateCurrentUserLoading(loading)); },
});


export default connect(mapStateToProps, mapDispatchToProps)(AuthRoutes);