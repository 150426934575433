/* External dependencies */
import React from 'react';
import { Modal } from 'react-bootstrap';

/* Internal dependencies */
import { ReactComponent as Logo } from '../assets/images/beatmatch-horizontal.svg';
import Button from '../button/Button';
import ConnectToFacebookButton from '../connectToFacebookButton/ConnectToFacebookButton';

type OwnProps = {
  show?: boolean;
  onSuccess?: () => void;
  onShow(): void;
  onClose(): void;
};

type Props = OwnProps;

class AuthModal extends React.Component<Props> {

  render() {
    const { show = false, onSuccess, onShow, onClose } = this.props;
    return (
      <Modal show={show} onShow={onShow} onHide={onClose}>
        <div className="d-flex justify-content-end pt-3 pr-2">
          <button onClick={onClose} className="btn">
            <span className="material-icons">close</span>
          </button>
        </div>
        <Modal.Body>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Logo className="d-block mb-2" width="60%" fill="#96f" style={{ height: 75 }} />
              <h5 className="mb-4">Sign in to continue</h5>
              <div className="mb-5">
                <ConnectToFacebookButton onSuccess={onSuccess} />
              </div>
              {/* <Button variant="link">Use Another Option</Button> */}
            </div>
        </Modal.Body>
      </Modal>
    );
  }
};

export default AuthModal;
