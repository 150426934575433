/* External dependencies */
import React from 'react'
import { match, Redirect, Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import moment from 'moment';
import sanitize from 'sanitize-html';

/* Internal dependencies */
import './Post.scss';
import { getPost, listPosts } from 'src/api/posts';
import Spinner from 'src/spinner/Spinner';
import Footer from 'src/footer/Footer';
import Navbar from 'src/navbar/Navbar';
import LogoAsURL from '../assets/images/beatmatch-horizontal.svg';
import MediaObject from 'src/mediaObject/MediaObject';

type InjectedProps = {
  match: match<{
    postId: string;
  }>;
};

type Props = InjectedProps;

type State = {
  post: any | undefined;
  relatedPosts: any[];
  loading: boolean;
};

class Post extends React.Component<Props, State> {
  state = { post: undefined, relatedPosts: [], loading: true };

  async componentDidMount() {
    await this.loadPost();
  }
  
  async componentDidUpdate({ match }: Props) {
    if (match.params.postId !== this.props.match.params.postId) {
      await this.loadPost();
    }
  }

  loadPost = async () => {
    const { match: { params } } = this.props;
    const { postId } = params;

    try {
      this.setState({ loading: true });
      const [post, { items: relatedPosts }] = await Promise.all([
        getPost(postId),
        listPosts(5),
      ]);
      var d = document, s = d.createElement('script');
      s.src = 'https://beatmatch.disqus.com/embed.js';
      s.setAttribute('data-timestamp', + new Date() as any);
      (d.head || d.body).appendChild(s);
      this.setState({ post, relatedPosts });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { post = {}, relatedPosts, loading } = this.state;
    const { id, title, body, date, subType, images, slug, providerId } = post! as any;
    const momentDate = date && moment(date);
    const metaTitle = `Beatmatch | ${title || 'The Beat'}`;
    const metaUrl = `https://${process.env.REACT_APP_DOMAIN_NAME}/the-beat/${providerId}/${slug}`;

    return (
      <div className="bg-white" style={{ minHeight: '100vh' }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTitle}</title>
          <meta property="og:description" content={title} />
          <meta property="og:url" content={metaUrl} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:image" content={images && images[0] && (images[0] as any).url || `https://${process.env.REACT_APP_DOMAIN_NAME}${LogoAsURL}`} />
          <meta property="og:site_name" content="Beatmatch" />
          <link rel="canonical" href={metaUrl} />
        </Helmet>
        <Navbar showLogo={true} />
        {loading ? <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}><Spinner /></div> : (
          <section className="bm-Post_wrapper d-flex justify-content-between container pt-5 pb-5">
            <section className="bm-Post d-flex flex-column align-items-start">
              {<h2 className="text-center mb-1">{title}</h2>}
              {momentDate && <p className="mb-4">Posted {momentDate.fromNow()}</p>}
              <div className="bm-Post_container d-flex flex-column align-items-start mb-5">
                {subType !== 'text' && images && images[0] && (
                  <div className="rounded mb-3 d-flex flex-column align-items-start">
                    <div className="rounded d-flex flex-column align-items-start">
                      <img
                        className="rounded"
                        src={images && (images[0] as any).url}
                        height="auto"
                        width="100%"
                        alt={slug}
                        style={{
                          flexShrink: 0,
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                  </div>
                )}
                {body && <div className="bm-Post_body d-flex flex-column align-items-start mb-1" dangerouslySetInnerHTML={{ __html: sanitize(body, {
                  allowedTags: ['p','div','img','b','i','ul','li','h1','h2','h3','h4','h5','h6','iframe','a'],
                  allowedAttributes: {
                    '*': ['*'],
                  },
                }) }} />}
              </div>
              <div id="disqus_thread" style={{ width: '100%' }}></div>
            </section>
            <section>
              <h3 className="text-bold mb-3">You May Also Like</h3>
              {relatedPosts.filter(({ id: postId }) => id !== postId).map(({ providerId, title, images, slug, date }) => (
                <Link to={`/the-beat/${providerId}/${slug}`} className="btn m-0 mb-3 p-0 d-flex flex-wrap" style={{ maxHeight: 1000 }}>
                  <MediaObject
                    text={title}
                    subtext={`Posted ${moment(date).fromNow()}`}
                    image={images && images[0] && (images[0] as any).url}
                  />
                </Link>
              ))}
            </section>
          </section>
        )}
        {!loading && !post && <Redirect to="/not-found" />}
        <Footer />
      </div>
    );
  }
}

export default Post;