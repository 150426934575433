/* External dependencies */
import Amplify, { Auth } from 'aws-amplify';
import { refreshFacebookCredentialForUser, addFacebookCredentialForUser } from './api/credentials';

export enum ApiName {
  bmApp = 'bmApp',
}

// const waitForInit = new Promise(res => {
// 	const fb = (window as any).FB;
// 	if (fb) {
// 		console.log('FB SDK already loaded');
// 		return res();
// 	} else {
// 		setTimeout(() => {
// 			return res();
// 		}, 2000);
// 	}
// });

// const refreshToken = async () => {
//   await waitForInit;
//   const authUser = await Auth.currentAuthenticatedUser();
//   const userId = `user:${authUser.id}`;
//   let fb = (window as any).FB;
//   if (!fb) {
//     console.log('no fb sdk available');
//     return Promise.reject('no fb sdk available');
//   }
//   console.log('refresh fb token')

//   return new Promise((res, rej) => {
//     fb.getLoginStatus(
//       async (fbResponse: any) => {
//         if (!fbResponse || !fbResponse.authResponse) {
//           console.log(
//             'no response from facebook when refreshing the jwt token'
//           );
//           return rej('no response from facebook when refreshing the jwt token');
//         }

//         const response = fbResponse.authResponse;
//         const { accessToken: access_token, id: providerId } = response;
//         console.log('response', response);
//         const { accessToken, expiresIn } = await addFacebookCredentialForUser(userId, { accessToken: access_token, providerId });
//         const date = new Date();
//         const expires_at = expiresIn * 1000 + date.getTime();
//         if (!accessToken) {
//           console.log('the jwtToken is undefined');
//           rej('the jwtToken is undefined');
//         }
//         res({ token: accessToken, expires_at });
//       },
//       { scope: 'public_profile,email' }
//     );
//   });
// };

const amplifyInit = () => {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      region: 'us-west-2', // hard-code region for user pool
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
      // refreshHandlers: {
      //   facebook: refreshToken,
      // }
    },
    API: {
      endpoints: [
        {
          name: ApiName.bmApp,
          endpoint: `https://api-${process.env.REACT_APP_STAGE || 'production'}.${process.env.REACT_APP_BASE_DOMAIN_NAME || 'beatmatchsocial.com'}`,
          region: process.env.REACT_APP_REGION,
        },
      ],
    },
  });
};

export default amplifyInit;