/* External dependencies */
import { API } from 'aws-amplify';

/* Internal dependencies */
import { ApiName } from '../aws-exports';


export const refreshFacebookCredentialForUser = async () => await API.get(ApiName.bmApp, '/credentials/facebook', null);

export const addFacebookCredentialForUser = async (userId: string, { accessToken, providerId }: any) => await API.post(ApiName.bmApp, `/users/${userId}/credentials/facebook`, {
  body: {
    accessToken,
    providerId
  },
});