/* External dependencies */
import React from 'react';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';

/* Internal dependencies */
import './Spinner.scss';

type OwnProps = {
  variant?: 'light' | 'dark'; 
};

type Props = OwnProps;

const Spinner: React.SFC<Props> = ({ variant = 'dark' }) => (
  <div className="bm-Spinner">
    <BootstrapSpinner variant={variant} animation="border" role="status">
      <span className="sr-only text-white">Loading...</span>
    </BootstrapSpinner>
  </div>
);

export default Spinner;