/* External dependencies */
import React from 'react';
import { Tabs, Tab, Badge, Image, Dropdown } from 'react-bootstrap';
import { match, withRouter } from 'react-router';
import { Link } from 'react-router-dom';

/* Internal dependencies */
import { getResultForUser } from '../api/results';
import { User } from '../types/User';
import ChartsTable from './ChartsTable';
import Item from '../item/Item';
import { ReactComponent as Logo } from '../assets/images/beatmatch-horizontal.svg';
import Spinner from '../spinner/Spinner';
import DefaultImage from '../assets/images/default-image.jpg';
import { CurrentUserState, getCurrentUser } from '../store/ducks/currentUser';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import Button from '../button/Button';
import './ViewResult.scss';

enum TabKey {
  answers = 'answers',
  friends = 'friends',
}

type InjectedProps = {
  match: match<{
    userId: string;
    resultId: string;
    tab: TabKey;
  }>;
  history: any;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type Props = InjectedProps & StateProps;

type State = {
  user: User | null;
  result: any;
  loading: boolean;
};

const IMAGE_SIZE = 150;

class ViewResult extends React.Component<Props, State> {
  state = { user: null, result: undefined, results: [], loading: true };

  async componentDidMount() {
    await this.load();
  }

  componentDidUpdate({ match: { params } }: any) {
    const { userId, resultId } = params;

    if (userId !== this.props.match.params.userId || resultId !== this.props.match.params.resultId) {
      console.log('loading')
      this.load();
    }
  }

  load = async () => {
    const { match: { params } } = this.props;
    const { userId, resultId } = params;

    try {
      this.setState({ loading: true });
      const wrappedUserId = `user:us-west-2:${userId}`;
      const wrappedResultId = `result:${resultId}`;
      const { user, result }: any = await getResultForUser(wrappedUserId, wrappedResultId);
      this.setState({ user, result });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { match: { params }, history, currentUser } = this.props;
    const { userId, resultId, tab = TabKey.answers } = params;
    const { user, result, loading } = this.state;
    const { score, responses = [], submitter } = result || {} as any;
    let SHARE_URL: string | undefined;
    if (typeof (window) !== "undefined") {
      SHARE_URL = window.location.href;
    }

    let headerText;
    let subHeaderText = `${user && (user as any).name}'s Taste Test (${score}%)`;
    if (submitter) {
      if (submitter.id === (currentUser && (currentUser as any).id)) {
        if (score < 70) {
          headerText = 'Sorry!';
          subHeaderText = `You did not pass ${user && (user as any).name}'s Taste Test (${score}%)`;
        } else {
          headerText = 'Congrats!';
          subHeaderText = `You passed ${user && (user as any).name}'s Taste Test (${score}%)`;
        }
      } else if (submitter.id !== (currentUser && (currentUser as any).id)) {
        if (score < 70) {
          headerText = 'Nope!'
          subHeaderText = `${submitter.name} did not pass ${user && (user as any).name}'s Taste Test (${score}%)`;
        } else {
          headerText = 'Yay!'
          subHeaderText = `${submitter.name} passed ${user && (user as any).name}'s Taste Test (${score}%)`;
        }
      }
    }

    return (
        <div style={{ position: 'relative', minHeight: '100vh', minWidth: '100vw' }}>
          <div className="bm-ViewTaste__bg" /> 
          <div style={{ minHeight: '100vh' }}>
            <div className="container" style={{ minHeight: '100vh' }}>
              <div className="col-sm-12 pt-5 pb-5" style={{ height: '100%' }}>
                <div className="d-flex flex-column align-items-center" style={{ height: '100%' }}>
                  <Link className="p-3 mb-4" to="/">
                    <Logo className="bm-navbar__logo" fill="#fff" />
                  </Link>
                  {loading || !user ? <Spinner variant="light" /> : (
                    <>
                      {headerText && <h1 className="text-center text-white mb-3">{headerText}</h1>}
                      <h4 className="text-center text-white mb-4">{subHeaderText}</h4>
                      <div className="d-flex">
                        {(submitter && submitter.id !== (user as any).id || !submitter) && <Link to={`/taste/${user && (user as any).id}`} style={{ position: 'relative', height: 'auto', width: '65%', marginRight: submitter ? '-3rem' : 0 }}><Image className="text-center mb-3" height="auto" width="100%" src={user && (user as any).images[0].url || DefaultImage} fluid={true} roundedCircle style={{ border: '5px solid #fff', maxWidth: IMAGE_SIZE, maxHeight: IMAGE_SIZE }} /></Link>}
                        {submitter && <Link to={`/taste/${submitter.id}`} style={{ height: 'auto', width: submitter && submitter.id !== (user as any).id ? '65%' : '100%' }}><Image className="text-center mb-4" height="auto" width="100%" src={submitter && submitter.images[0].url || DefaultImage} fluid={true} roundedCircle style={{ border: '5px solid #fff', maxWidth: IMAGE_SIZE, maxHeight: IMAGE_SIZE }} /></Link>}
                      </div>
                      <div className="d-flex flex-column align-items-center pl-2 pr-2" style={{ minWidth: '60vw' }}>
                        <Tabs className="d-flex justify-content-center flex-nowrap tabs-white" fill={false} defaultActiveKey={tab} activeKey={tab} onSelect={(eventKey: string | null) => {
                          history.push(`/taste/${(userId)}/results/${resultId}${eventKey === TabKey.friends ? `/${eventKey}` : ''}`);
                          window.scrollTo(0, 0);
                        }} id="uncontrolled-tab-example">
                          <Tab eventKey={TabKey.answers} title={<h6 style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: 1, overflow: 'hidden' }}>{`${submitter ? `${ submitter.id === (currentUser && currentUser.id) ? 'Your' : `${(submitter as any).name}'s`} ` : ''}Answers`}</h6>} style={{ minWidth: '60vw' }}>
                            <div>
                            {responses.map((response: any, i: number) => {
                              if (!response) return null;
                              const { prompt, items, id, correct } = response;
                              const variant: any = correct ? 'success' : 'danger';
                              return (
                                <div key={id} className="p-3 bg-white rounded mb-3" style={{ width: '100%', border: '0.5px solid #e2e2e2', maxHeight: 200 }}>
                                  <div className="d-flex justify-content-between align-items-center mb-4">
                                    <h6 className="d-flex flex-wrap m-0 p-0" style={{ maxWidth: '85%' }}>{i+1}. {(prompt as any).title}</h6>
                                    <div className="d-flex align-items-center">
                                      <Badge className="d-flex justify-content-center align-items-center" pill variant={variant} style={{ height: 40, width: 40 }}>
                                        <span className="d-flex align-items-center">
                                          <i className={`icon ${correct ? 'ion-ios-checkmark' : 'ion-ios-close'} text-white`} style={{ fontSize: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', height: 40, width: 40 }} />
                                          {/* {correct ? 'Correct' : 'Incorrect' } */}
                                        </span>
                                      </Badge>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <Item item={items[0]} />
                                  </div>
                                </div>
                              );
                            })}
                            </div>
                          </Tab>
                          <Tab eventKey={TabKey.friends} title={<h6 style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: 3, overflow: 'hidden' }}>{`${(user as any).name}'s Top Friends`}</h6>} style={{ minWidth: '60vw' }}>
                            <ChartsTable userId={(user as any).id} />
                          </Tab>
                        </Tabs>
                      </div>
                      <div className="d-flex justify-content-center flex-wrap mt-4" style={{ marginBottom: 50, width: '100%' }}>
                        <Button
                          type="button"
                          className="bm-ViewResult_actionButton"
                          disabled={!user}
                          onClick={() => {
                            history.push(`/taste/${(user! && (user as any).id! && (user as any)!.id!.split(':'))[2]}`);
                          }}
                          style={{ borderRadius: 20 }}
                        >
                          <i className="fa fa-repeat mr-2" /> <h6 className="p-0 m-0 text-bold">{submitter.id === (currentUser && currentUser.id) ? 'Take It Again' : `Take ${user && (user as any).name}'s Taste Test`}</h6>
                        </Button>
                        <Button
                          type="button"
                          className="btn btn-secondary btn-block"
                          variant="secondary"
                          dropdownClassName="bm-ViewResult_actionButton"
                          dropdownItems={[
                            <Dropdown.Item
                              key="facebook"
                              href={`https://www.facebook.com/sharer/sharer.php?display=popup&u=${SHARE_URL}`}
                              target="_blank"
                            >
                              <i className="fa fa-facebook mr-3" style={{ fontSize: 16 }} />Share To Facebook
                            </Dropdown.Item>,
                            <Dropdown.Item
                              key="twitter"
                              href={`https://twitter.com/share?url=${SHARE_URL}`}
                              target="_blank"
                            >
                              <i className="fa fa-twitter mr-3" style={{ fontSize: 16 }} />Share To Twitter
                            </Dropdown.Item>,
                            <Dropdown.Item
                              key="copy"
                              onClick={() => {
                                const tempInput = document.createElement('input') as any;
                                tempInput.style = "position: absolute; left: -1000px; top: -1000px";
                                tempInput.value = SHARE_URL;
                                document.body.appendChild(tempInput);
                                tempInput.select();
                                document.execCommand("copy");
                                document.body.removeChild(tempInput);
                              }}
                            >
                              Copy link
                            </Dropdown.Item>,
                          ]}
                          style={{ borderRadius: 20 }}
                        >
                          <i className="fa fa-share mr-2" /> <h6 className="p-0 m-0 text-bold">Share Your Result</h6>
                        </Button>
                        <Button
                          type="button"
                          className="bm-ViewResult_actionButton"
                          disabled={!currentUser}
                          onClick={() => {
                            history.push(`/taste/${(currentUser! && (currentUser as any).id! && currentUser!.id.split(':'))[2]}/edit`);
                          }}
                          style={{ borderRadius: 20 }}
                        >
                          <i className="fa fa-plus mr-2" /> <h6 className="p-0 m-0 text-bold">Create Your Own</h6>
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  currentUser: getCurrentUser(state),
});

export default connect(mapStateToProps)(withRouter<any, any>(ViewResult));