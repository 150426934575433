/* External dependencies */
import React, { FormEvent } from 'react';
import { match, Redirect, withRouter } from 'react-router';
import { Image, Container, ProgressBar, Tabs, Tab, Badge, Table } from 'react-bootstrap';
import { shuffle, uniqBy } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

/* Internal dependencies */
import { User } from '../types/User';
import Spinner from '../spinner/Spinner';
import Item from '../item/Item';
import Stepper from '../stepper/Stepper';
import Button from '../button/Button';
import './ViewTaste.scss';
import { ApplicationState } from '../store';
import { getCurrentUser, CurrentUserState, getCurrentUserLoading } from '../store/ducks/currentUser';
import { ReactComponent as Logo } from '../assets/images/beatmatch-horizontal.svg';
import LogoAsURL from '../assets/images/beatmatch-horizontal.svg';
import { addResultForUser } from '../api/results';
import ChartsTable from './ChartsTable';
import AuthRoutes from '../AuthRoutes';
import { getTasteTestForUser } from '../api/tasteTest';
import AuthModal from '../authModal/AuthModal';
import ScreenshotViewTasteCover from '../assets/images/screenshot-view-taste-cover.png';
import ScreenshotViewTaste from '../assets/images/screenshot-view-taste.png';
import ScreenshotViewResults from '../assets/images/screenshot-view-results.png';
import iPhone from '../assets/images/iphone.png';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import { ReactComponent as Bolt } from '../assets/images/bolt.svg';
import DefaultUserImage from '../assets/images/default-image.png';
import { NotFound } from '../App';
import ConfirmationModal from 'src/confirmationModal/ConfirmationModal';
import { addNotification } from 'src/store/ducks/notifications';
import { Dispatch } from 'redux';
import { Notification } from 'src/types/Notification';

type InjectedProps = {
  match: match<{ userId: string }>;
  history: any;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
  currentUserLoading: CurrentUserState['loading'];
};

type DispatchProps = {
  addNotification(notification: Omit<Notification, 'id'>): void;
};

type Props = InjectedProps & StateProps & DispatchProps;

type State = {
  user: User | undefined;
  answers: any[];
  trackRecommendations: any[];
  artistRecommendations: any[];
  loading: boolean;
  activeStepIndex: number;
  selectedItems: {
    [promptId: string]: any;
  };
  showAuthModal: boolean;
};

class ViewTaste extends React.Component<Props, State> {
  slideInterval: any;
  
  state = { user: undefined, answers: [], trackRecommendations: [], artistRecommendations: [], activeStepIndex: 0, loading: true, selectedItems: {}, showAuthModal: false, currentSlideIndex: 0 };
  hasShuffled: any = {};

  async componentDidMount() {
    const { match: { params } } = this.props;
    const { userId } = params;
    const wrappedUserId = `user:us-west-2:${userId}`;

    try {
      const { user, answers = [], recommendations = [] }: { user: User, answers: any[], recommendations: any[] } = await getTasteTestForUser(wrappedUserId);
        this.setState({ user, answers, trackRecommendations: recommendations, artistRecommendations: recommendations.reduce((acc: any[], curr: any) => [...acc, ...curr.artists], []).filter((artists: any) => artists.images.length) });
        // TODO: add better empty state...something to prompt them to add answers but only if the currently logged in user matches the user from the test
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  handlePrevClick = () => {
    this.setState(({ activeStepIndex }) => ({ activeStepIndex: activeStepIndex - 1 }));
    if (typeof (window) !== "undefined") {
      window.scrollTo(0, 0); 
    }
  };

  handleNextClick = () => {
    this.setState(({ activeStepIndex }) => ({ activeStepIndex: activeStepIndex + 1 }));
    if (typeof (window) !== "undefined") {
      window.scrollTo(0, 0);
    }
  };

  createHandleNextClick = (promptId: string, item: any) => () => {
    this.setState(({ activeStepIndex, selectedItems }) => ({
      activeStepIndex: activeStepIndex + 1,
      selectedItems: {
        ...selectedItems,
        [promptId]: { ...item, promptId },
      },
    }));
    if (typeof (window) !== "undefined") {
      window.scrollTo(0, 0);
    }
  };

  getScoreAndResponses = () => {
    const { answers = [], trackRecommendations, artistRecommendations, loading, activeStepIndex, selectedItems } = this.state;
    let numOfCorrect = 0;
    const responses = answers.map(({ prompt, items }) => {
      const selectedItem = (selectedItems as any)[(prompt as any).id];
      if (!selectedItem) return null;
      const [item] = items;
      let correct = false;

      if (selectedItem.id === (item as any).id) {
        numOfCorrect++;
        correct = true;
      }
      return { id: (prompt as any).id, prompt, promptId: (prompt as any).id, items: [(selectedItems as any)[(prompt as any).id]], correct };
    });

    const score = Math.floor(numOfCorrect / answers.length * 100);

    return { score, responses };
  };

  submit = async () => {
    const { match: { params }, history } = this.props;
    const { answers } = this.state;
    const { userId } = params;
    const { score, responses } = this.getScoreAndResponses();

    const wrappedUserId = `user:us-west-2:${userId}`;
    const result = await addResultForUser(wrappedUserId, { score, responses, answers });
    history.push(`/taste/${userId}/results/${result.id}`);
  };

  handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const { currentUser } = this.props;
    if (!currentUser) {
      this.setState({ showAuthModal: true });
      return;
    }
    await this.submit();
  };

  handleAuthSuccess = async () => {
    const { currentUser, addNotification } = this.props;
    const { user } = this.state;
    const isPreview = (currentUser && (currentUser as any).id) === (user && (user as any).id);
    if (!isPreview) {
      await this.submit();
    } else {
      addNotification({ title: 'Sorry', message: 'You cannot respond to your own Taste Test!', variant: 'secondary' });
      this.setState({ showAuthModal: false });
    }
  };

  render() {
    const { currentUser, history, match: { params }  } = this.props;
    const { userId } = params;
    const { user, answers = [], trackRecommendations, artistRecommendations, loading, activeStepIndex, showAuthModal } = this.state;

    if (loading) return (
      <>
        <div className="bm-ViewTaste__bg d-flex justify-content-center align-items-center">
        {/* <Navbar alwaysFillNavbar={false} /> */}
        <Spinner variant="light" />
        </div>
      </>
    );
    if (!user) return <NotFound />;

    const steps = [
      {
        content: (
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex flex-column align-items-center pl-1 pr-1">
              <h1 className="text-center text-white">{`${user && (user as any).name}'s`}</h1>
              <h1 className="text-center text-white mb-4">Taste Test</h1>
            </div>
            <div className="d-flex justify-content-center mb-3">
              {(currentUser && currentUser.id !== (user as any).id || !currentUser) && (
                <div style={{ position: 'relative', width: '100%', maxWidth: 250, maxHeight: 250, objectFit: 'cover', marginRight: currentUser ? '-3rem' : 0 }}>
                  <Image className="text-center mb-3" height="auto" width="100%" src={user && (user as any).images[0].url || DefaultUserImage} fluid={true} roundedCircle style={{ position: 'relative', border: '5px solid #fff', objectFit: 'cover', marginRight: currentUser ? '-3rem' : 0 }} />
                </div>
              )}
              <div style={{ width: '100%', maxWidth: 250, maxHeight: 250, objectFit: 'cover', marginLeft: currentUser && currentUser.id !== (user as any).id || !currentUser ? '-4rem' : 0 }}>
                <div style={{ position: currentUser && currentUser.id === (user && (user as any).id) ? 'relative' : undefined }}>
                  <Image className="text-center mb-4" height="auto" width="100%" src={currentUser && currentUser.images[0].url || DefaultUserImage} fluid={true} roundedCircle style={{ border: '5px solid #fff', objectFit: 'cover' }} />
                  {currentUser && currentUser.id === (user as any).id && (
                    <Button className="btn bg-white d-flex justify-centent-center align-items-center" style={{ maxHeight: 1000, height: 60, width: 60, position: 'absolute', bottom: '3rem', right: 0, borderRadius: 75 / 2 }} type="button" onClick={() => {
                      history.push(`/taste/${(user as any).id.split(':')[2]}/edit`);
                    }}>
                      <i className="fa fa-pencil" style={{ fontSize: '1.75rem', color: '#4b4b4b' }} />
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <p className="text-center text-white pl-3 pr-3 mb-5 lead" style={{ maxWidth: 500 }}>
              {user && (user as any).name}{` made a test to see who knows ${user && (user as any).gender === 'male' ? 'his' : user && !(user as any).gender ? 'their' : 'her'} music taste best. Prove you're a true friend by passing the test!`}
            </p>
            <Button type="button" onClick={this.handleNextClick} style={{ minWidth: 250, borderRadius: 20 }}>
              <h4 className="m-0">Let's Go</h4>
            </Button>
          </div>
        )
      },
      ...answers.map(({ prompt, items = [] }, i) => {
        const [item]: any = items;
        const { type } = item;
        const recommendations = type === 'track' ? trackRecommendations.slice(i * 3, (i * 3) + 3) : artistRecommendations.slice(i * 3, (i * 3) + 3);
        const allItems = shuffle(uniqBy([item, ...recommendations], 'id'));

        return { content: (
          <React.Fragment>
            {allItems.map((item, i) => (
              <button type="button" onClick={this.createHandleNextClick((prompt as any).id, item)} key={i} className="btn p-3 bg-white rounded mb-3 bm-ViewTaste d-flex flex-wrap" style={{ width: '100%', border: '0.5px solid #e2e2e2', maxHeight: 200, maxWidth: '100%' }}>
                <Item item={item} />
              </button>
            ))}
          </React.Fragment>
        )};
      }),
      {
        content: (
          <div className="pt-0 p-4 bg-white rounded d-flex justify-content-center align-items-center flex-wrap">
            {(user && (user as any).id) !== (currentUser && currentUser.id) && (
              <div className="container" style={{ maxWidth: '80%' }}>
                <h5>That's it!</h5>
                <p className="m-0 mb-4">{`Ready to find out how well you know ${user && (user as any).name}'s taste in music? See if you passed the test.`}</p>
                <button
                  type="submit"
                  className="btn btn-primary btn-block d-flex align-items-center d-flex justify-content-center"
                >
                  See Your Results
                </button>
              </div>
            )}
            {(user && (user as any).id) === (currentUser && currentUser.id) && (
              <div className="container" style={{ maxWidth: '80%' }}>
                <h5>That's it!</h5>
                <p className="m-0 mb-4">You've reached the end of the preview. You cannot submit your own test. Please choose one of the options below.</p>
                <button
                  type="button"
                  className="btn btn-primary btn-block d-flex align-items-center d-flex justify-content-center"
                  onClick={() => { history.push(`/taste/${(userId)}/edit`); }}
                >
                  Edit Your Taste Test
                </button>
                <button
                  type="button"
                  className="btn btn-secondary btn-block d-flex align-items-center d-flex justify-content-center"
                  onClick={() => { this.setState({ activeStepIndex: 0, selectedItems: {} }) }}
                >
                  Take It Again
                </button>
              </div>
            )}
          </div>
        ),
      },
    ];

    const stepsHeader = activeStepIndex === 0 ? <div /> : (
      <div className="d-flex flex-column align-items-center">
        {answers[activeStepIndex - 1] && (answers[activeStepIndex - 1] as any).prompt.title && (
          <div className="d-flex justify-content-center align-items-center mb-5 bg-white" style={{ position: 'relative', borderRadius: 20, minHeight: 200, width: '100%', padding: '5%' }}>
            <i className="fa fa-quote-left fa-3x mr-3" style={{ marginTop: -20 }} />
            <h4 className="text-center mr-3 d-flex flex-wrap" style={{ fontWeight: 600 }}>{answers[activeStepIndex - 1] && (answers[activeStepIndex - 1] as any).prompt.title}</h4>
            <i className="fa fa-quote-right fa-3x" style={{ marginTop: 20 }} />
            <div className="d-flex align-items-center" style={{ position: 'absolute', bottom: -20, left: -20 }}>
              <Image className="text-center mr-2" src={user && (user as any).images[0].url || DefaultUserImage} fluid={true} roundedCircle style={{ border: '5px solid #fff', maxWidth: 90, maxHeight: 90 }} />
              <h5 className="text-center" style={{ fontWeight: 600 }}>{user && (user as any).name}</h5>
            </div>
          </div>
        )}
      </div>
    );

    const pageTitle = `Beatmatch | ${user && (user as any).name}'s Taste Test`;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
          <meta property="og:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}/taste/${userId}`} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={`How well do you know ${user && (user as any).name}?`} />
          <meta property="og:image" content={`https://${process.env.REACT_APP_DOMAIN_NAME}${LogoAsURL}`} />
          <meta property="og:site_name" content="Beatmatch" />
          <meta property="twitter:description" content={`How well do you know ${user && (user as any).name}?`} />
          <meta property="twitter:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}/taste/${userId}`} />
          <meta property="twitter:image" content={`https://${process.env.REACT_APP_DOMAIN_NAME}${LogoAsURL}`} />
          <meta property="twitter:title" content="Beatmatch" />
          <meta property="twitter:card" content="summary" />
          <link rel="canonical" href={`https://${process.env.REACT_APP_DOMAIN_NAME}/taste`} />
        </Helmet>
        <>
          <ConfirmationModal message="You will lose all your response if you leave before completing the test. Are you sure?" />
          <AuthModal
            show={showAuthModal}
            onSuccess={this.handleAuthSuccess}
            onShow={() => { this.setState({ showAuthModal: true }); }}
            onClose={() => { this.setState({ showAuthModal: false }); }}
          />
          <div style={{ position: 'relative', height: '100vh' }}>
            <div className="bm-ViewTaste__bg" />
            <div style={{ height: '100vh' }}>
              <div className="container" style={{ height: '100%' }}>
                <div className="col-sm-12 pt-5 pb-5" style={{ height: '100%' }}>
                  <div className="d-flex flex-column justify-content-between align-items-center" style={{ height: '100%' }}>
                    <Link className="p-3 mb-2" to="/">
                      <Logo className="bm-navbar__logo" fill="#fff" />
                    </Link>
                    <div className="pl-2 pr-2" style={{ marginLeft: 'auto', marginRight: 'auto', minHeight: activeStepIndex !== 0 ? '100vh' : undefined, width: '100%' }}>
                      <form onSubmit={this.handleSubmit}>
                        <Stepper steps={steps} stepsHeader={stepsHeader} activeStepIndex={activeStepIndex} />
                      </form>
                      {activeStepIndex !== 0 && activeStepIndex < steps.length - 2 && (
                        <div className="d-flex justify-content-center align-items-start mt-1" style={{ maxHeight: 20 }}>

                            <div className="d-flex justify-content-center align-items-center" style={{ flex: 1 }}>
                              <div className="mr-3" style={{ flex: 4, maxWidth: '75%' }}>
                                <ProgressBar color="#fff" animated now={(activeStepIndex / answers.length) * 100} label={`(${activeStepIndex}/${answers.length})`} srOnly />
                              </div>
                              <h6 className="text-white">{`(${activeStepIndex}/${answers.length})`}</h6>
                            </div>
                        </div>
                      )}
                    </div>
                    <div />
                    <div />
                  </div>
                </div>
              </div>
            </div>
            {(currentUser && (currentUser as any).id) === (user && (user as any).id) && (
            <div className="d-flex justify-content-between align-items-center p-1" style={{ width: '100%', position: 'fixed', top: 0, left: 0, backgroundColor: 'rgba(52,58,64, 0.85)' }}>
              <div />
              <p className="text-white text-center p-0 m-0" style={{ flex: 1 }}>This is a preview of your Taste Test.</p>
              <button className="btn" onClick={history.goBack}><i className="fa fa-close text-white" /></button>
            </div>
          )}
          </div>
        </>
      </>
    );
  }
};

const mapStateToProps = (state: ApplicationState) => ({ currentUser: getCurrentUser(state), currentUserLoading: getCurrentUserLoading(state) });

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: Omit<Notification, 'id'>) => { dispatch(addNotification(notification)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewTaste);

class Unauthenticated extends React.Component<InjectedProps & StateProps, { showAuthModal: boolean; currentSlideIndex: number; }> {
  slideInterval: any;

  state = { showAuthModal: false, currentSlideIndex: 0 };

  componentDidMount() {
    if (typeof (window) !== "undefined") {
      this.slideInterval = window.setInterval(() => { this.setState(({ currentSlideIndex }) => ({ currentSlideIndex: (currentSlideIndex + 1) % 3 })); }, 3500);
    }
  }

  componentWillUnmount() {
    if (typeof (window) !== "undefined") {
      window.clearInterval(this.slideInterval);
    }
  }

  handleAuthSuccess = () => {
    const { match: { params }, history } = this.props;
    const { userId } = params;
    history.push(`/taste/${(userId)}/edit`);
  };

  resetSlideInterval = () => {
    if (typeof (window) !== "undefined") {
      if (this.slideInterval) {
        window.clearInterval(this.slideInterval);
      }

      this.slideInterval = window.setInterval(() => { this.setState(({ currentSlideIndex }) => ({ currentSlideIndex: (currentSlideIndex + 1) % 3 })); }, 5000);
    }
  }

  createOnDotClick = (slideIndex: number) => () => { this.setState({ currentSlideIndex: slideIndex }, () => { this.resetSlideInterval(); }) };
  
  render() {
    const { currentUser, currentUserLoading } = this.props;
    const { showAuthModal, currentSlideIndex } = this.state;

    if (currentUserLoading) {
      return (
        <>
          <Navbar alwaysFillNavbar={false} />
          <Spinner />
        </>
      );
    }

    if (currentUser) {
      return (
        <AuthRoutes showNavbar={false}>
          <Redirect to={`/taste/${currentUser!.id.split(':')[2]}/edit`} />
        </AuthRoutes>
      );
    }
    
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Beatmatch | Taste Test – How well do you know your friends?</title>
          <meta property="description" content="Put your friendships to the test with music. The ultimate music taste quiz." />
          <meta property="og:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}/taste`} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Beatmatch | Taste Test – How well do you know your friends?" />
          <meta property="og:description" content="Put your friendships to the test with music. The ultimate music taste quiz." />
          <meta property="og:image" content={`https://${process.env.REACT_APP_DOMAIN_NAME}${LogoAsURL}`} />
          <meta property="og:site_name" content="Beatmatch" />
          <meta property="twitter:description" content="Put your friendships to the test with music. The ultimate music taste quiz." />
          <meta property="twitter:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}/taste`} />
          <meta property="twitter:image" content={`https://${process.env.REACT_APP_DOMAIN_NAME}${LogoAsURL}`} />
          <meta property="twitter:title" content="Beatmatch | Taste Test – How well do you know your friends?" />
          <meta property="twitter:card" content="summary" />
          <link rel="canonical" href={`https://${process.env.REACT_APP_DOMAIN_NAME}/taste`} />
        </Helmet>
        <Navbar alwaysFillNavbar={false} />
        <section className="bm-ViewTaste__bg--unauthenticated d-flex justify-content-center align-items-center" style={{ position: 'relative', width: '100%', overflow: 'hidden' }}>
          <div style={{ position: 'absolute' }}>
            <Bolt />
          </div>
          <div className="d-flex justify-content-center align-items-center" style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
            <div className="d-flex justify-content-start align-items-end container" style={{ height: '100%' }}>
              <div className="bm-ViewTaste__leftSide pt-3 pb-3 d-flex justify-content-between align-items-center" style={{ flex: 4, height: '100%' }}>
                <div className="d-flex flex-column justify-content-start">
                  <h1 className="bm-ViewTaste_heading pt-3 mb-4" style={{ color: '#fff' }}>Put your friendships to the test <span className="text-primary">with music</span></h1>
                  <h3 className="bm-ViewTaste_subheading mb-5" style={{ color: '#fff' }}><b>Challenge friends to see how well they know your music taste.</b></h3>
                  <Button className="bm-ViewTaste_getting_started_button" type="button" variant="primary" onClick={() => { this.setState({ showAuthModal: true }); }} style={{ borderRadius: 20 }}>
                    <h5 className="text-white p-0 m-0">Get Started</h5>
                  </Button>
                </div>
              </div>
              <div className="bm-ViewTaste__rightSide flex-column justify-content-end align-items-between" style={{ flex: 3, height: '100%', overflow: 'hidden' }}>
                <div className="ml-5 d-lg-block d-xl-block" style={{ position: 'relative', maxWidth: '60%', marginBottom: '-7.5rem' }}>
                  <Image className={`bm-ViewTaste__slide ${currentSlideIndex === 0 ? 'bm-ViewTaste__slide--current' : ''} d-none d-lg-block d-xl-block`} fluid={true} height={450} width={450} src={ScreenshotViewTasteCover} style={{ position: 'absolute', bottom: 0, zIndex: 10, display: 'block', height: 'auto', width: 'auto', marginBottom: '-1rem', borderRadius: 40, transform: 'scale(0.94)', maxWidth: '34vh' }} />
                  <Image className={`bm-ViewTaste__slide ${currentSlideIndex === 1 ? 'bm-ViewTaste__slide--current' : ''} d-none d-lg-block d-xl-block`} fluid={true} height={450} width={450} src={ScreenshotViewTaste} style={{ position: 'absolute', bottom: 0, zIndex: 20, display: 'block', height: 'auto', width: 'auto', marginBottom: '-1rem', borderRadius: 40, transform: 'scale(0.94)', maxWidth: '34vh' }} />
                  <Image className={`bm-ViewTaste__slide ${currentSlideIndex === 2 ? 'bm-ViewTaste__slide--current' : ''} d-none d-lg-block d-xl-block`} fluid={true} height={450} width={450} src={ScreenshotViewResults} style={{ position: 'absolute', bottom: 0, zIndex: 30, display: 'block', height: 'auto', width: 'auto', marginBottom: '-1rem', borderRadius: 40, transform: 'scale(0.94)', maxWidth: '34vh' }} />
                  <Image className="d-none d-lg-block d-xl-block" fluid={true} height={450} width={450} src={iPhone} style={{ zIndex: 40, position: 'absolute', bottom: 0, maxWidth: '34vh' }}/>
                </div>
                <div className="d-flex justify-content-end">
                  <div className="d-flex justify-content-between align-items-center pb-3 mr-5" style={{ width: '5rem' }}>
                    <button onClick={this.createOnDotClick(0)} className={`btn bm-ViewTaste__dot ${currentSlideIndex === 0 ? 'bm-ViewTaste__dot--current' : ''}`} />
                    <button onClick={this.createOnDotClick(1)} className={`btn bm-ViewTaste__dot ${currentSlideIndex === 1 ? 'bm-ViewTaste__dot--current' : ''}`} />
                    <button onClick={this.createOnDotClick(2)} className={`btn bm-ViewTaste__dot ${currentSlideIndex === 2 ? 'bm-ViewTaste__dot--current' : ''}`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container bm-center_children bg-white d-flex flex-column align-items-center" style={{ height: '100%', width: '100%', paddingTop: 100, paddingBottom: 100 }}>
            <h2 className="text-center pt-5 mb-5">How it works</h2>
            <div className="d-flex justify-content-between flex-wrap">
              <div className="d-flex flex-column align-items-center mb-4" style={{ flex: 1 }}> 
                <div className="bm-center_children bm-Home__sectionHalf rounded mb-4" style={{ maxWidth: 350, maxHeight: 200 }}>
                  <div className="d-flex justify-content-center align-items-center bg-primary" style={{ borderRadius: 175, width: 200, height: 200 }}>
                    <i className="fa fa-quote-right" style={{ fontSize: '5rem', color: '#fff' }}/>
                  </div>
                </div>
                <div>
                  <h4 className="text-center"><b>Pick your Prompts</b></h4>
                  <p className="text-center">Select 10 prompts that would best describe your unique music taste.</p>
                </div>
              </div>
              <div className="d-flex flex-column align-items-center mb-4" style={{ flex: 1 }}> 
                <div className="bm-center_children bm-Home__sectionHalf rounded mb-4" style={{ maxWidth: 350, maxHeight: 200 }}>
                  <div className="d-flex justify-content-center align-items-center bg-primary" style={{ borderRadius: 175, width: 200, height: 200 }}>
                    <i className="fa fa-music" style={{ fontSize: '5rem', color: '#fff' }}/>
                  </div>
                </div>
                <div>
                  <h4 className="text-center"><b>Choose your Answers</b></h4>
                  <p className="text-center">Search for songs or artists that answer each prompt. Try to stump your friends!</p>
                </div>
              </div>
              <div className="d-flex flex-column align-items-center mb-4" style={{ flex: 1 }}> 
                <div className="bm-center_children bm-Home__sectionHalf rounded  mb-4" style={{ maxWidth: 350, maxHeight: 200 }}>
                  {/* <img className="img img-fluid" src={Screenshot} style={{ maxHeight: 200 }} /> */}
                  <div className="d-flex justify-content-center align-items-center bg-primary" style={{ borderRadius: 175, width: 200, height: 200 }}>
                    <i className="fa fa-paper-plane" style={{ fontSize: '5rem', color: '#fff' }}/>
                  </div>
                </div>
                <div>
                  <h4 className="text-center"><b>Share with Friends</b></h4>
                  <p className="text-center">Challenge your friends and family to see how well they really know you!</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <AuthModal
          show={showAuthModal}
          onSuccess={this.handleAuthSuccess}
          onShow={() => { this.setState({ showAuthModal: true }); }}
          onClose={() => { this.setState({ showAuthModal: false }); }}
        />
        <Footer />
      </>
    );
  }
};

export const ViewTasteRedirect = connect(mapStateToProps)(withRouter<any, any>(Unauthenticated)); 
