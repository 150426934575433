/* External dependencies */
import React from 'react';

/* Internal dependencies */
import { ReactComponent as Logo } from '../assets/images/beatmatch-horizontal.svg';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className="bm-Footer d-flex flex-column justify-content-center align-items-center p-4">
      <div className="bm-Footer__container d-flex justify-content-between align-items-center flex-wrap">
        <Logo fill="#fff" width="40%" style={{ width: 200 }} />
        <div>
          <a className="bm-Footer__social_link mr-4" href="https://instagram.com/beatmatchsocial" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-instagram" style={{ fontSize: 28 }} />
          </a>
          <a className="bm-Footer__social_link mr-4" href="https://facebook.com/beatmatchsocial" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-facebook" style={{ fontSize: 28 }} />
          </a>
          <a className="bm-Footer__social_link mr-4" href="https://twitter.com/beatmatchsocial" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-twitter" style={{ fontSize: 28 }} />
          </a>
          <a className="bm-Footer__social_link mr-4" href="https://open.spotify.com/user/bakkvmm7qkhmy1nq5kk12naxh" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-spotify" style={{ fontSize: 28 }} />
          </a>
          <a className="bm-Footer__social_link" href="https://twitch.tv/beatmatchsocial" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-twitch" style={{ fontSize: 25 }} />
          </a>
        </div>
      </div>
      <div>
        <p className="text-white">© 2020 Beatmatch | All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;